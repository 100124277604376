import React from "react"
import Layout from "../components/layout"
import { Container, Row, Col } from "react-bootstrap"

import PSOPNT154 from "../images/logos/PSOP-No-Tag-154.png"
import PSOPWT154 from "../images/logos/PSOP-With-Tag-154.png"
import PSPNT154 from "../images/logos/PSP-No-Tag-154.png"
import PSPWT154 from "../images/logos/PSP-With-Tag-154.png"

import PSOPNT200 from "../images/logos/PSOP-No-Tag-200.png"
import PSOPWT200 from "../images/logos/PSOP-With-Tag-200.png"
import PSPNT200 from "../images/logos/PSP-No-Tag-200.png"
import PSPWT200 from "../images/logos/PSP-With-Tag-200.png"

import PSOPNT300 from "../images/logos/PSOP-No-Tag-300.png"
import PSOPWT300 from "../images/logos/PSOP-With-Tag-300.png"
import PSPNT300 from "../images/logos/PSP-No-Tag-300.png"
import PSPWT300 from "../images/logos/PSP-With-Tag-300.png"

import PSOPNT400 from "../images/logos/PSOP-No-Tag-400.png"
import PSOPWT400 from "../images/logos/PSOP-With-Tag-400.png"
import PSPNT400 from "../images/logos/PSP-No-Tag-400.png"
import PSPWT400 from "../images/logos/PSP-With-Tag-400.png"

import PSOPNT500 from "../images/logos/PSOP-No-Tag-500.png"
import PSOPWT500 from "../images/logos/PSOP-With-Tag-500.png"
import PSPNT500 from "../images/logos/PSP-No-Tag-500.png"
import PSPWT500 from "../images/logos/PSP-With-Tag-500.png"

const Logos = () => {
  return (
    <>
      <Layout>
        <div className="logos-wrapper">
          <div className="logos-content">
            <Container style={{ padding: "200px 15px" }}>
              <h2 className="text-center benefits-flair mb-5 ">Logos</h2>
              <h3 className="mb-4 underline">154 Pixel Wide</h3>
              <Row className="justify-content-start mb-5 gx-4">
                <Col md={3} className="mb-5">
                  <img
                    src={PSOPNT154}
                    className="img-fluid"
                    alt="PSOP No Tag 154"
                  />
                </Col>
                <Col md={3} className="mb-5">
                  <img
                    src={PSOPWT154}
                    className="img-fluid"
                    alt="PSOP With Tag 154"
                  />
                </Col>
                <Col md={3} className="mb-5">
                  <img
                    src={PSPNT154}
                    className="img-fluid"
                    alt="PSP No Tag 154"
                  />
                </Col>
                <Col md={3} className="mb-5">
                  <img
                    src={PSPWT154}
                    className="img-fluid"
                    alt="PSP With Tag 154"
                  />
                </Col>
              </Row>
              <h3 className="mb-4 underline">200 Pixel Wide</h3>
              <Row className="justify-content-start mb-5 gx-4">
                <Col md={6} lg={3} className="mb-5">
                  <img
                    src={PSOPNT200}
                    className="img-fluid"
                    alt="PSOP No Tag 200"
                  />
                </Col>
                <Col md={6} lg={3} className="mb-5">
                  <img
                    src={PSOPWT200}
                    className="img-fluid"
                    alt="PSOP With Tag 200"
                  />
                </Col>
                <Col md={6} lg={3} className="mb-5">
                  <img
                    src={PSPNT200}
                    className="img-fluid"
                    alt="PSP No Tag 200"
                  />
                </Col>
                <Col md={6} lg={3} className="mb-5">
                  <img
                    src={PSPWT200}
                    className="img-fluid"
                    alt="PSP With Tag 200"
                  />
                </Col>
              </Row>
              <h3 className="mb-4 underline">300 Pixel Wide</h3>
              <Row className="justify-content-start mb-5">
                <Col md={6} lg={3} className="mb-5">
                  <img
                    src={PSOPNT300}
                    className="img-fluid"
                    alt="PSOP No Tag 300"
                  />
                </Col>
                <Col md={6} lg={3} className="mb-5">
                  <img
                    src={PSOPWT300}
                    className="img-fluid"
                    alt="PSOP With Tag 300"
                  />
                </Col>
                <Col md={6} lg={3} className="mb-5">
                  <img
                    src={PSPNT300}
                    className="img-fluid"
                    alt="PSP No Tag 300"
                  />
                </Col>
                <Col md={6} lg={3} className="mb-5">
                  <img
                    src={PSPWT300}
                    className="img-fluid"
                    alt="PSP With Tag 300"
                  />
                </Col>
              </Row>
              <h3 className="mb-4 underline">400 Pixel Wide</h3>
              <Row className="justify-content-start mb-5 g-5">
                <Col md={6} className="mb-5">
                  <img
                    src={PSOPNT400}
                    className="img-fluid"
                    alt="PSOP No Tag 400"
                  />
                </Col>
                <Col md={6} className="mb-5">
                  <img
                    src={PSOPWT400}
                    className="img-fluid"
                    alt="PSOP With Tag 400"
                  />
                </Col>
                <Col md={6} className="mb-5">
                  <img
                    src={PSPNT400}
                    className="img-fluid"
                    alt="PSP No Tag 400"
                  />
                </Col>
                <Col md={6} className="mb-5">
                  <img
                    src={PSPWT400}
                    className="img-fluid"
                    alt="PSP With Tag 400"
                  />
                </Col>
              </Row>
              <h3 className="mb-4 underline">500 Pixel Wide</h3>
              <Row className="justify-content-start mb-5">
                <Col md={6} className="mb-5">
                  <img
                    src={PSOPNT500}
                    className="img-fluid"
                    alt="PSOP No Tag 500"
                  />
                </Col>
                <Col md={6} className="mb-5">
                  <img
                    src={PSOPWT500}
                    className="img-fluid"
                    alt="PSOP With Tag 500"
                  />
                </Col>
                <Col md={6} className="mb-5">
                  <img
                    src={PSPNT500}
                    className="img-fluid"
                    alt="PSP No Tag 500"
                  />
                </Col>
                <Col md={6} className="mb-5">
                  <img
                    src={PSPWT500}
                    className="img-fluid"
                    alt="PSP With Tag 500"
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Logos
